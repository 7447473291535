.cAccount{
    border-radius:10px;
    border-style: solid;
    border-color: lightgray;
    padding: 10px;
}

.cForm{
    border-radius:10px;
    border-style: solid;
    border-color: lightgray;
    padding: 10px;
    width: 100%;
}

.cLabel{
    font-weight: bold;
}

.cSwitch{
    align-items: right;
    padding: 5px;
}

.cTable{
    border-radius:10px;
    border-style: solid;
    border-color: lightgray;
    padding: 10px;
    width: 100%;
}
.cChk{
    margin-left:10px;
}