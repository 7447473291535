.cInterceptor{
  border-radius:10px;
  border-style: solid;
  border-color: lightgray;
  padding: 10px;
}

.cForm{
  border-radius:10px;
  border-style: solid;
  border-color: lightgray;
  padding: 10px;
  width: 35%;
}

.cLabel{
  font-weight: bold;
}

.cSwitch{
  align-items: right;
  padding: 5px;
}

.cTable{
  border-radius:10px;
  border-style: solid;
  border-color: lightgray;
  padding: 10px;
  width: 100%;
}

.leaflet-container {
  border-radius: 4px;
  border: 1px solid #ced4da;
  height: 100%;
  width: 100%;
  margin-bottom: 15px;
}

.map-container {
  height: 400px;
  width: 100%;
  margin-bottom: 20px;
}
